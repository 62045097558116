.projects-component {
  margin: 0 auto;
  margin-top: 40px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  > .header {
    padding: 20px;
    > .title {
      color: var(--title-color);
      font-size: 30px;
      margin-bottom: 16px;
    }

    > .desc {
      color: var(--desc-color);
      font-size: 22px;
      line-height: 28px;
      max-width: 620px;
    }
  }

  > .content {
    border-bottom: 1px solid #454f6f7a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 20px;
    margin-top: 70px;

    > .project {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
      margin-bottom: 100px;

      .info {
        display: flex;
        flex-direction: column;
        align-self: center;
        height: 100%;
        justify-content: center;

        &.left {
          margin-right: 34px;
        }

        &.right {
          margin-left: 34px;
        }

        > .title {
          color: #dde2f4;
          font-size: 22px;
          margin-bottom: 8px;
        }

        > .desc {
          color: var(--desc-color);
          line-height: 28px;
          font-size: 18px;
        }
      }

      > .image {
        &.left {
          margin-right: 34px;
        }

        &.right {
          margin-left: 34px;
        }

        > img {
          height: 396px;
          width: 528px;
          border-radius: 6px;

          &.c {
            object-fit: cover;
            height: 352px;
          }

          &.b {
            object-position: bottom;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .projects-component {
    margin: 0 auto;
    margin-top: 40px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    > .header {
      padding: 20px;
      > .title {
        color: var(--title-color);
        font-size: 20px;
        margin-bottom: 16px;
      }

      > .desc {
        color: var(--desc-color);
        font-size: 15px;
        line-height: 28px;
        max-width: 620px;
      }
    }

    > .content {
      border-bottom: 1px solid #454f6f7a;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1100px;
      margin: 0 20px;
      margin-top: 70px;

      > .project {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
        margin-bottom: 100px;
        align-items: center;

        .info {
          display: flex;
          flex-direction: column;
          align-self: center;
          height: 100%;
          justify-content: center;

          &.left,
          &.right {
            margin-right: unset;
            margin-left: unset;
          }

          > .title {
            color: #dde2f4;
            font-size: 18px;
            margin-bottom: 8px;
            margin-top: 20px;
          }

          > .desc {
            color: var(--desc-color);
            line-height: 28px;
            font-size: 14px;
          }
        }

        > .image {
          margin-left: unset;
          margin-right: unset;

          &.left,
          &.right {
            margin-left: unset;
            margin-right: unset;
          }

          // &.right {
          //   margin-left: 34px;
          // }

          > img {
            height: 200px;
            width: 290px;
            border-radius: 6px;

            &.c {
              object-fit: cover;
              height: 194px;
            }

            &.b {
              object-position: bottom;
            }
          }
        }
      }
    }
  }
}
