.App {
  text-align: center;
  background-color: var(--background-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/world.bmp");
    filter: grayscale(50%);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.18;
    transform: rotateY(180deg);
  }

  &::after {
  }

  > .content {
    width: 100%;
  }

  > .dot {
    position: absolute;
    width: 100%;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      max-width: 100%;
      height: 362px;
      background: url("./assets/icons/dot-shadow.svg");
      background-repeat: no-repeat;
      background-size: auto;
      left: 50%;
      top: 0;
      background-position: center bottom;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
    }
  }

  // ----------------------------------------------------------------------------------------------------------------------------------

  @keyframes move-background {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
    }
    to {
      -webkit-transform: translate3d(1000px, 0px, 0px);
    }
  }
  @-webkit-keyframes move-background {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
    }
    to {
      -webkit-transform: translate3d(1000px, 0px, 0px);
    }
  }

  @-moz-keyframes move-background {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
    }
    to {
      -webkit-transform: translate3d(1000px, 0px, 0px);
    }
  }

  @-webkit-keyframes move-background {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
    }
    to {
      -webkit-transform: translate3d(1000px, 0px, 0px);
    }
  }

  .background-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.07;
  }

  .stars {
    background: black url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png") repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 0;
  }

  .twinkling {
    width: 10000px;
    height: 100%;
    background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png") repeat;
    background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;

    -moz-animation: move-background 10s linear infinite;
    -ms-animation: move-background 10s linear infinite;
    -o-animation: move-background 10s linear infinite;
    -webkit-animation: move-background 10s linear infinite;
    animation: move-background 10s linear infinite;
  }

  .clouds {
    width: 10000px;
    height: 100%;
    background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/clouds_repeat.png") repeat;
    background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;

    -moz-animation: move-background 70s linear infinite;
    -ms-animation: move-background 70s linear infinite;
    -o-animation: move-background 70s linear infinite;
    -webkit-animation: move-background 70s linear infinite;
    animation: move-background 70s linear infinite;
  }

  img.moon-bg {
    height: 30vh;
    width: 30vh;
    position: absolute;
    z-index: 3;
    // left: 20px;
    // top: -40px;
    opacity: 0.5;
    float: left;
    //
    margin: auto;
    bottom: 0;
    left: 0;
    -moz-transition: all 0.8s ease-out;
    -o-transition: all 0.8s ease-out;
    -webkit-transition: all 0.8s ease-out;
    transition: all 0.8s ease-out;
    transform-origin: center;
    animation: move-moon 120s infinite linear;
  }
}

html[data-scroll^="500"] img.moon-bg {
  width: 800px;
  height: 800px;
  bottom: 50%;
  left: 50%;
  transform: rotateZ(90deg);
}

@keyframes move-moon {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .App {
    img.moon-bg {
      height: 20vh;
      width: 20vh;
      bottom: 0;
      left: 0;
    }

    .s-video-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .svcc {
        width: 80%;
      }
    }
  }

  html[data-scroll^="500"] img.moon-bg {
    width: 380px;
    height: 380px;
    bottom: 50%;
    left: 50%;
    transform: rotateZ(90deg);
  }

  @keyframes move-moon {
    from {
      transform: rotateZ(0deg);
    }

    to {
      transform: rotateZ(360deg);
    }
  }
}
