:root {
  --background-color: #161822;

  --primary-color: #309;
  --secondary-color: #09f;

  --title-color: #dde2f4;
  --desc-color: #99a1ba;
}

@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Bold.ttf");
  font-weight: bold;
}

html,
body {
  margin: 0;
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  height: 100%;
}

p {
  margin: unset;
}
