.sections-component {
  margin: 0 auto;
  margin-top: 40px;
  padding-bottom: 20px;

  display: flex;
  justify-content: center;

  > .content {
    border-bottom: 1px solid #454f6f7a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 0 20px;
    padding-bottom: 60px;

    > .section {
      flex: 1 1 29%;
      margin-bottom: 40px;
      padding-right: 34px;

      > .title {
        color: #dde2f4;
        text-align: left;
        font-size: 22px;
        margin-bottom: 8px;
      }

      > .desc {
        color: var(--desc-color);
        text-align: left;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .sections-component {
    margin: 0 auto;
    margin-top: 40px;
    padding-bottom: 20px;

    display: flex;
    justify-content: center;

    > .content {
      border-bottom: 1px solid #454f6f7a;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1100px;
      margin: 0 20px;
      padding-bottom: 0;
      align-items: center;
      justify-content: center;

      > .section {
        flex: 1 1 29%;
        margin-bottom: 40px;
        padding: 10px;

        > .title {
          color: #dde2f4;
          text-align: left;
          font-size: 16px;
          margin-bottom: 8px;
        }

        > .desc {
          color: var(--desc-color);
          text-align: left;
          font-size: 14px;
          line-height: 28px;
        }
      }
    }
  }
}
