.header-component {
  align-items: center;
  margin-top: 100px;
  // margin-bottom: 100px;
  // height: 80vh;

  > .intro-video-component {
    width: 80%;

    > .content {
      border-bottom: unset;
    }
  }

  display: flex;
  flex-direction: column;
  > img {
    width: 120px;
    // object-position: 0 35px;
  }

  > .title {
    margin-top: 12px;
    font-size: 32px;
    color: #5b9bd5;
    font-weight: bold;
    letter-spacing: 4px;

    > span {
      color: #9dc3e6;
    }
  }

  > .label {
    margin-top: 12px;
    font-size: 24px;
    color: var(--desc-color);
  }

  > .desc {
    margin-top: 24px;
    font-size: 22px;
    color: var(--desc-color);
    text-align: center;
    max-width: 520px;
    padding: 0 24px;
  }
}

@media only screen and (max-width: 600px) {
  .header-component {
    align-items: center;
    // margin-top: 100px;
    // margin-bottom: 100px;
    // height: 80vh;

    > .intro-video-component {
      width: 80%;

      > .content {
        border-bottom: unset;
      }
    }

    display: flex;
    flex-direction: column;
    > img {
      width: 80px;
      // object-position: 0 35px;
    }

    > .title {
      margin-top: 12px;
      font-size: 32px;
      color: #5b9bd5;
      font-weight: bold;
      letter-spacing: 4px;

      > span {
        color: #9dc3e6;
      }
    }

    > .label {
      margin-top: 12px;
      font-size: 24px;
      color: var(--desc-color);
    }

    > .desc {
      margin-top: 24px;
      font-size: 16px;
      color: var(--desc-color);
      text-align: center;
      max-width: 520px;
      padding: 0 24px;
    }
  }
}
