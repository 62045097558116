.navbar-component {
  position: sticky;
  top: -1px;
  // padding: 20px 80px;
  padding: 20px 100px;

  border-top: 1px solid #454f6f7a;
  border-bottom: 1px solid #454f6f7a;
  background-color: #16182261;
  margin: 0 200px;
  transition: 0.2s;
  z-index: 1;
  // max-width: 960px;

  &.isSticky {
    margin: 0;
    // padding: 20px 140px;
    padding: 20px 300px;

    background-color: #272c42;

    // padding-left: 50px;

    > .content {
      > .logo {
        position: absolute;
        top: 0;
        > .tech-logo {
          opacity: 1;

          // display: block;
          // margin-right: 240px;
        }
      }
    }
  }

  > .content {
    // max-width: 960px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    > .logo {
      position: absolute;
      top: 0;

      > .tech-logo {
        opacity: 0;
        transition: 0.3s;
        width: 36px;

        position: absolute;
        left: -210px;
        top: -9px;
        // display: none;
      }
    }

    > .item {
      cursor: pointer;
      color: var(--desc-color);
      font-size: 21px;
      white-space: nowrap;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .navbar-component {
    position: sticky;
    top: -1px;
    // padding: 20px 80px;
    padding: 20px 8px;

    border-top: 1px solid #454f6f7a;
    border-bottom: 1px solid #454f6f7a;
    background-color: #16182261;
    margin: 0 20px;
    transition: 0.2s;
    z-index: 1;
    // max-width: 960px;

    &.isSticky {
      margin: 0;
      // padding: 20px 140px;
      padding: 20px;
      // padding-top: 48px;
      padding-left: 70px;

      background-color: #272c42;

      // padding-left: 50px;

      > .content {
        > .logo {
          position: absolute;
          top: 0;
          > .tech-logo {
            opacity: 1;

            // display: block;
            // margin-right: 240px;
          }
        }
      }
    }

    > .content {
      // max-width: 960px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;

      > .logo {
        position: absolute;
        top: 0;

        > .tech-logo {
          opacity: 0;
          transition: 0.3s;
          width: 28px;

          position: absolute;
          left: -54px;
          top: -9px;
          // display: none;
        }
      }

      > .item {
        cursor: pointer;
        color: var(--desc-color);
        font-size: 15px;
        white-space: nowrap;
        text-decoration: none;
      }
    }
  }
}
