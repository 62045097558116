.footer-component {
  background-color: #272c42;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  > .content {
    color: var(--desc-color);
    font-size: 14px;
    white-space: nowrap;
  }
}
