.intro-video-component {
  margin: 0 auto;
  margin-top: 40px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  > .header {
    padding: 20px;
    > .title {
      color: var(--title-color);
      font-size: 30px;
      margin-bottom: 16px;
    }

    > .desc {
      color: var(--desc-color);
      font-size: 22px;
      line-height: 28px;
      max-width: 620px;
    }
  }

  > .content {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #454f6f7a;
    max-width: 1100px;
    width: 100%;
    padding: 40px;
    padding-bottom: 100px;

    > #introVideo {
      border: 4px solid #272c42;
      height: 625px !important;
      max-height: 625px !important;
      width: 100% !important;
      // filter: sepia(100%) grayscale(0.5) saturate(500%) brightness(0.7) hue-rotate(190deg);
      transition: 0.6s;
    }

    &.playing {
      > #introVideo {
        // filter: sepia(0%) grayscale(0) saturate(100%) brightness(1) hue-rotate(0deg);
      }
    }
  }

  > .content.small {
    > #introVideo {
      border: 4px solid #272c42;
      height: 425px !important;
      max-height: 425px !important;
      max-width: 625px !important;
      // filter: sepia(100%) grayscale(0.5) saturate(500%) brightness(0.7) hue-rotate(190deg);
      transition: 0.6s;
    }
  }
}

@media only screen and (max-width: 600px) {
  .intro-video-component {
    margin: 0;
    margin-top: 20px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // margin: 20px;

    > .header {
      padding: 20px;
      > .title {
        color: var(--title-color);
        font-size: 20px;
        margin-bottom: 16px;
      }

      > .desc {
        color: var(--desc-color);
        font-size: 15px;
        line-height: 28px;
        max-width: 620px;
      }
    }

    > .content {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #454f6f7a;
      max-width: 1100px;
      width: 100%;
      padding: 10px 0;
      padding-bottom: 0px;

      > #introVideo {
        border: 4px solid #272c42;
        height: 225px !important;
        max-height: 625px !important;
        width: 100% !important;
        // filter: sepia(100%) grayscale(0.5) saturate(500%) brightness(0.7) hue-rotate(190deg);
        transition: 0.6s;
      }

      &.playing {
        > #introVideo {
          // filter: sepia(0%) grayscale(0) saturate(100%) brightness(1) hue-rotate(0deg);
        }
      }
    }

    > .content.small {
      > #introVideo {
        border: 4px solid #272c42;
        height: 200px !important;
        max-height: 425px !important;
        max-width: 625px !important;
        // filter: sepia(100%) grayscale(0.5) saturate(500%) brightness(0.7) hue-rotate(190deg);
        transition: 0.6s;
      }
    }
  }
}
